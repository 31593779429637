import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router";
import detectEthereumProvider from '@metamask/detect-provider';

function Profile({account, getBalance, claim, getTransactions, isController}) {
  const navigate = useNavigate();
  const [stories, setStories] = useState([]);
  const [tippedStories, setTippedStories] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [showClaim, setShowClaim] = useState(true);

  useEffect(() => {

    detectEthereumProvider()
    .then(provider => {
      provider.on('accountsChanged', () => {
        navigate('/');
      })
    })

    if(account){
      fetch(`/api/user/${account}/stories`)
        .then((res) => res.json())
        .then((data) => {
          setStories(data)
        })

      fetch(`/api/user/${account}/drafts`)
        .then((res) => res.json())
        .then((data) => setDrafts(data))

      fetch(`/api/reader/${account}/tips`)
        .then((res) => res.json())
        .then((data) => setTippedStories(data))

        fetch(`/api/user/${account}/allowance`)
        .then((res) => res.json())
        .then((data) => {
          console.log(data)
          if(data.amount != 0){
            setShowClaim(false)
          }else{
            setShowClaim(true)
          }
        })

    }else{
      alert('You are not logged in. Try connecting your wallet')
    }

    getBalance()
      .then(res => {setBalance(res.toString())})

    getTransactions()
      .then(res => {
        console.log(res)
        return setTransactions(res)
      })

  },[]);

  const getHtmlText = (html) => {
    return html.replace(/<[^>]+>/g, '').substring(0, 50);
  }

  const dayFrom = (date) => {
    var diff = new Date().getTime() - new Date(date).getTime();
    diff = Math.floor(diff / (1000 * 60 * 60 * 24));
    return diff ? diff : 0;
  }

  const handleDraftClick = (draftId) => {
    navigate(`/write/${draftId}`)
  }

  const handleStoryClick = (storyId) => {
    navigate(`/story/${storyId}`)
  }

  const deleteDraft = (draftId) => {
    fetch(`/api/draft/${draftId}`, {method: 'DELETE'})
      .then(response => {
        fetch(`/api/user/${account}/drafts`)
        .then((res) => res.json())
        .then((data) => setDrafts(data))
    })
  }

  return (
    <div className="profile">
      <div className="container">

        <div className="row mt-2">
          <div className="col">
            <h3>Balance: {balance} FLWR</h3>
          </div>
          <div className="col">
            {/* <h3>Claim Available: x FLWR</h3> */}
          </div>
          <div className="col">
            <button hidden={showClaim} className="btn btn-secondary w-100" onClick={()=>claim()}>CLAIM 100 FLWR</button>
          </div>
        </div>

        <h1>Published Stories</h1>
        <div className="row">
          {stories.map((story) => (
            <div className="card mt-1 mb-1 col-4" onClick={() => handleStoryClick(story.storyId)}>
              <div className="card-body">
                <h5 className="card-title">{story.vine}...</h5>
                <small>Published {dayFrom(story.createdAt)} days ago</small>
                <p className="card-text overflow-auto">{getHtmlText(story.content)}</p>
              </div>
            </div>
          ))}
        </div>

        <h1>Drafts</h1>
        <div className="row">
          {drafts.map((draft) => (
            <div className="card mt-1 mb-1 col-4">
              <div className="card-body">
                <div className="row">
                  <h5 className="card-title col-10" onClick={() => handleDraftClick(draft.draftId)}>{draft.vine}...</h5>
                  <img src={process.env.PUBLIC_URL + '/trash-icon.png'} height="15rem" width="20rem" onClick={() => deleteDraft(draft.draftId)}></img>
                </div>
                <small>Published {dayFrom(draft.createdAt)} days ago</small>
                <p className="card-text overflow-auto">{getHtmlText(draft.content)}</p>
              </div>
            </div>
          ))}
        </div>

        <h1>Tipped Stories</h1>
        <div className="row">
          {tippedStories.map((tippedStory) => (
            <div className="card mt-1 mb-1 col-4" onClick={() => handleStoryClick(tippedStory.storyId)}>
              <div className="card-body">
                <h5 className="card-title">Author: {tippedStory.author}</h5>
                <p className="card-text overflow-auto">Story: {tippedStory.storyId}</p>
                <p className="card-text overflow-auto">Amount: {tippedStory.amount} FLWR</p>
              </div>
            </div>
          ))}
        </div>

        <h1>Transactions</h1>
        <div className="row">
           {transactions.map((transaction) => (
              <div className="card mt-1 mb-1 col-4">
                <div className="card-body">
                  <h5 className="card-title">Event: {transaction.event}</h5>
                  <small>{(transaction.event == "Transfer") ? `To: ${transaction.args.to}` : `Contract Address: ${transaction.address}`}</small>
                  <p className="card-text overflow-auto">{(transaction.event == "Transfer") ? `Amount: ${transaction.args.value} FLWR` : `Transaction Hash: ${transaction.transactionHash}`}</p>
                </div>
              </div>
           ))}
        </div>

      </div>
    </div>
  );
}

export default Profile;
