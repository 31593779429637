import React from "react";
import logo from "../tsv-logo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Search() {
    const navigate = useNavigate();
    const [vine, setVine] = useState('');
    const [searchInterval, setSearchInterval] = useState(null);
    const [vines, setVines] = useState([]);
    const [localVines, setLocalVines] = useState([]);

    const search = event => {
        const vineName = event.target.value
        setVine(vineName)
        if(vineName){
            var queriedVines = localVines;
            var currentVines = queriedVines.filter(e => { return e.vineName.toLowerCase().includes(vineName.toLowerCase())})
            if(currentVines.length < 1){
                fetch(`/api/vines?vineName=${encodeURI(vineName)}`)
                    .then(response => response.json())
                    .then(data => {
                        queriedVines = queriedVines.concat(data)
                        currentVines = queriedVines.filter(e => { return e.vineName.toLowerCase().includes(vineName.toLowerCase())})
                        setLocalVines(queriedVines)
                        setVines(currentVines)
                    });
            }else{
                setVines(currentVines)
            }
        }
    }

    const handleRedirect = (vineName) => {
        fetch(`/api/vine/${encodeURI(vineName)}/stories?limit=1`)
          .then((res) => res.json())
          .then((data) => { navigate(`/story/${data[0].storyId}`) })
    }

    return (
    <div className="search">
        <div className="container col-xs-12 col-lg-6">
            <div className="row align-items-center mt-5">
                <div className="col-lg-12">
                    <input list="vineName" id="vineBox" className="font-weight-light w-100" placeholder="Vine..." onChange={search}/>
                    {vines.map((vine) => (
                        <div className="card" key={vine.vineName} onClick={() => handleRedirect(vine.vineName)}>
                            <div className="card-body">
                                {vine.vineName}...
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    );
}

export default Search;
