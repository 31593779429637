import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import {Button, Modal, Toast, Fade} from 'react-bootstrap';
import DOMPurify from "dompurify";

function Post({account, transfer}) {
  let { postSlug } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState({});
  const [reads, setReads] = useState(0);
  const [tip, setTip] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showToast, setToast] = useState(false);

  useEffect(() => {
      fetch(`/api/story/${postSlug}`)
      .then((res) => res.json())
      .then((data) => { 
        setStory(data);
        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            vine: data.vine
          })
        };
        fetch(`/api/story/${postSlug}/read`, options)
        .then(response => response.json());
      });

      fetch(`/api/story/${postSlug}/reads`)
        .then(response => response.json())
        .then(data => {setReads(data.length)})

  }, [postSlug]);

  const handleNext = (event) => {
    fetch(`/api/vine/${encodeURI(story.vine)}/stories?limit=1&lastKey=${postSlug}`)
      .then((res) => res.json())
      .then((data) => { 
        if(data.length > 0){
          navigate(`/story/${data[0].storyId}`)
        }else{
          alert('No stories left in this vine')
        }
      })
  };

  const handleTipChange = (event) => {
    setTip(event.target.value);
  };

  const cleanHTML = (html) => {
    var pureHtml =  DOMPurify.sanitize(html, {
      USE_PROFILES: { html: true },
    });
    pureHtml = pureHtml.replace('<a', '<a target=\"_blank\"')
    return pureHtml;
  }

  const dayFrom = (date) => {
    var diff = new Date().getTime() - new Date(date).getTime();
    if(diff > (1000 * 60 * 60 * 24 * 7 * 4 * 12)){
      return `${Math.floor(diff / (1000 * 60 * 60 * 24 * 7 * 4 * 12))} years`;
    }else if(diff > (1000 * 60 * 60 * 24 * 7 * 4)){
      return `${Math.floor(diff / (1000 * 60 * 60 * 24 * 7 * 4))} months`;
    } else if(diff > (1000 * 60 * 60 * 24 * 7)){
      return `${Math.floor(diff / (1000 * 60 * 60 * 24 * 7))} weeks`;
    }else if(diff > (1000 * 60 * 60 * 24)){
      return `${Math.floor(diff / (1000 * 60 * 60 * 24))} days`;
    }else if(diff > (1000 * 60 * 60)){
      return `${Math.floor(diff / (1000 * 60 * 60))} hours`;
    }else if(diff > (1000 * 60)){
      return `${Math.floor(diff / (1000 * 60))} minutes`;
    }else{
      return `${Math.floor(diff / 1000)} seconds`;
    }
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    setToast(true)
  }

  const handleTransfer = () => {
    transfer(story.author, tip)
    .then(transaction => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          vine: story.vineName,
          author: story.author,
          tipper: account,
          amount: tip,
          transactionHash: transaction
        })
      };
      fetch(`/api/story/${postSlug}/tip`, options)
        .then(response => response.json());
    })
  }

  const handleWriteStory = (vineName) => {
    navigate(`/write?vine=${encodeURI(vineName + '...')}`)
  }

  return (
    <div className="home h-100">
      <div className="container story-container col-xs-12 col-lg-6">

        <Toast className="position-fixed w-100" onClose={() => setToast(false)} show={showToast} delay={750} autohide transition={Fade}>
          <Toast.Body>Copied to clipboard</Toast.Body>
        </Toast>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>How much would you like to tip?</Modal.Title>
            <button className="btn" onClick={handleClose}>x</button>
          </Modal.Header>
          <Modal.Body>
            <div className="row d-flex justify-content-center">
              <input className="col-3 text-center" type="number" min="0" value={tip} onChange={handleTipChange}></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="white" onClick={handleTransfer}>
              SEND FLWR
            </Button>
          </Modal.Footer>
        </Modal>

        <h1 className="mt-2" onClick={()=>handleWriteStory(story.vine)}>{story.vine}...</h1>
        <i class="fa-solid fa-pen-to-square"></i>
        <small>Published {dayFrom(story.createdAt)} ago</small>
        {/* <small>Author {story.author}</small> */}
        <div id="storyDiv" dangerouslySetInnerHTML={{ __html: cleanHTML(story.content) }} />

        <div className="container fixed-bottom col-xs-12 col-lg-6 mb-4">

          <div className="align-self-end">
            <div className="row">
              <div className="col">
                <p>{reads} READS</p>
              </div>
              <div className="col">
                <button className="btn w-100" onClick={handleNext}>NEXT →</button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button className="btn btn-secondary w-100" onClick={copyUrl}>COPY URL</button>
              </div>
              <div className="col">
                <button className="btn btn-white w-100" disabled={(account==null || story.author == 'anonymous') ? true : false} onClick={handleShow}>SEND SOME FLWR</button>
              </div>
            </div>
          </div>
          
        </div>

      </div>
    </div>
  );
}

export default Post;