import React from "react";

function About() {
  return (
    <div className="about">
      <div class="container col-xs-12 col-lg-6">
        <div class="row align-items-center my-5">
          <div class="col-lg-12">
            <h1 class="font-weight-light">The story vine...</h1>
            <p>
              Welcome to social as it should be -- Completely anonymous, Community-owned.
            </p>
            <p><br /></p>
            <p>
              The story vine is a Web3 platform where the individual rules and free
              expression reigns.
            </p>
            <p><br /></p>
            <p>Stories are organized into "common streams of consciousness" -- Vines...</p>
            <p><br /></p>
            <p>
              <a
                href="https://thestoryvine.com/story/4186edb7-57da-438d-a941-abd0e310d6f5"
                rel="noopener noreferrer"
                target="_blank"
                >2023...</a
              >
            </p>
            <p>
              <a
                href="https://thestoryvine.com/story/60c5b6f2-eb5f-429f-bdbc-d01b8debf79b"
                rel="noopener noreferrer"
                target="_blank"
                >I always knew I was gay...</a
              >
            </p>
            <p>
              <a
                href="https://thestoryvine.com/story/a70c314c-c3c4-4082-b487-1d24670ca807"
                rel="noopener noreferrer"
                target="_blank"
                >Coronavirus...</a
              >
            </p>
            <p>
              <a
                href="https://thestoryvine.com/story/72ed7fbe-19b7-4113-ba7f-22995bce7674"
                rel="noopener noreferrer"
                target="_blank"
                >I am grateful for...</a
              >
            </p>
            <p>
              <a
                href="https://thestoryvine.com/story/41e5be1d-8b18-4a8d-8421-db35484e8a24"
                rel="noopener noreferrer"
                target="_blank"
                >I hate my job...</a
              >
            </p>
            <p><br /></p>
            <p>
              <a
                href="https://docs.google.com/document/d/1Cc-ZBwxDprj9dZCGlB1vPewvVwsXhARhi6hO1_wM7us/edit?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
                >Connect a blockchain wallet</a
              >
              to create an anonymous profile and access your Published Stories and Saved
              Drafts. We recommend
              <a href="https://metamask.io/" rel="noopener noreferrer" target="_blank"
                >MetaMask</a
              >.&nbsp;
            </p>
            <p><br /></p>
            <p>Wallets also empower you to interact with our native crypto token, FLWR.</p>
            <p><em>Claim Free FLWR </em>after Publishing your first Story.</p>
            <p><em>Earn FLWR</em> by Publishing quality content.</p>
            <p>
              <em>Send Some FLWR</em> directly to the Author of any Story you find
              impactful.
            </p>
            <p><br /></p>
            <p>Forever free of fees. Forever free of ads.</p>
            <p><br /></p>
            <p>Welcome to the sentient mesh.</p>
            <p><br /></p>
            <p>
              Intro Video:
              <a
                href="https://vimeo.com/44396002"
                rel="noopener noreferrer"
                target="_blank"
                >https://vimeo.com/44396002</a
              >
            </p>
            <p><br /></p>
            <p>
              Press:
              <a
                href="http://patch.com/new-york/parkslope/the-story-vine-park-slope-startup-shares-secrets-anonymously"
                rel="noopener noreferrer"
                target="_blank"
                >http://patch.com/new-york/parkslope/the-story-vine-park-slope-startup-shares-secrets-anonymously</a
              >
            </p>
            <p><br /></p>
            <p>
              Say Hi:
              <a
                href="mailto:hello@thestoryvine.com"
                rel="noopener noreferrer"
                target="_blank"
                >hello@thestoryvine.com</a
              >
            </p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
