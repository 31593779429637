import React from "react";
import { Outlet } from "react-router-dom";

function Story() {
  return (
        <Outlet />
  );
}

export default Story;
