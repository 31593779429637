import './App.css';
import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation, Profile, Home, About, Write, Story, Post, Search, Controller } from "./components";

import detectEthereumProvider from '@metamask/detect-provider';
import { useAccount } from '@web3modal/react';
import { chains } from '@web3modal/ethereum';
import { ethers, providers } from "ethers";
import Token from './crypto/artifacts/contracts/ThestoryvineFlowerToken.sol/ThestoryvineFlowerToken.json';

export default function App() {

  const config = {
    projectId: process.env.REACT_APP_ALCHEMY_PROJECT_ID,
    theme: 'dark',
    accentColor: 'blackWhite',
    ethereum: {
      appName: 'theStoryVine',
      autoConnect: true,
      chains: process.env.REACT_APP_ENV != "PROD" ? [chains.localhost, chains.goerli] : [chains.mainnet]
    }
  }

  const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
  const { account, isReady } = useAccount()

  useEffect(() => {
    detectEthereumProvider()
  },[]);

  async function getBalance(){
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(tokenAddress, Token.abi, provider)
      const balance = ethers.utils.formatEther(await contract.balanceOf(account.address));
      //console.log(balance)
      fetch(`/api/user/${account.address}/allowance`)
      return balance
    }
  }

  async function claim(){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, Token.abi, signer);
    const allowanceResponse = await fetch(`/api/user/${account.address}/allowance`);
    const permit = await allowanceResponse.json();
    const transaction = await contract.claim(permit.amount, permit.deadline, permit.v, permit.r, permit.s)
    await transaction.wait();
    //console.log(`${amount} Coins successfully sent to ${account}`);
  }

  async function transfer(receivingAddress, amount){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, Token.abi, signer);
    const DECIMAL = ethers.BigNumber.from(10).pow(18);
    const transaction = await contract.transfer(receivingAddress, ethers.BigNumber.from(amount).mul(DECIMAL));
    // await transaction.wait();
    // console.log(transaction)
    return transaction;
  }

  async function getTransactions(){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, Token.abi, signer);
    const transferFrom = await contract.queryFilter(contract.filters.Transfer(null, account.address))
    const transferTo = await contract.queryFilter(contract.filters.Transfer(account.address, null))
    let events = transferFrom.concat(transferTo);
    return events;
  }

  async function getAllTransactions(){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, Token.abi, signer);
    const transactions = await contract.queryFilter(1)
    console.log(transactions)
    return transactions;
  }

  async function getTransactionFromHash(hash){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return await provider.getTransaction(hash)
  }

  async function getTotalSupply(){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, Token.abi, signer);
    const totalSupply = await contract.totalSupply()
    return totalSupply;
  }

  async function isController(){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenAddress, Token.abi, signer);
    const isController = await contract.isController()
    return isController;
  }

  return (
    <Router>
      <Navigation account={account.address} config={config}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/write" element={ <Write account={account.address}  /> } >
          <Route path=":draftSlug" element={<Write account={account.address} /> } />
        </Route>
        <Route path="/about" element={<About  />} />
        <Route path="/search" element={<Search account={account.address}  />}></Route>
        <Route path="/story" element={<Story account={account.address}  />}>
          <Route path=":postSlug" element={<Post account={account.address} transfer={transfer} />} />
        </Route>
        <Route path="/profile" element={account.address ? <Profile account={account.address} getBalance={getBalance} claim={claim} getTransactions={getTransactions} isController={isController} /> : null} />
        <Route path="/controller" element={account.address ? <Controller account={account.address} getBalance={getTotalSupply} getAllTransactions={getAllTransactions} /> : null} />
      </Routes>
    </Router>
  );
}
