import React from "react";
import logo from "../tsv-logo.svg";
import favicon from "../favicon.png";
import { Navbar, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useConnectModal, Web3Modal } from '@web3modal/react';

function Navigation({account, config}) {
  const { isOpen, open, close } = useConnectModal();

  const navigate = useNavigate();
  const profile = event => {
    navigate(`/profile`)
  }

  const setCollapse = (event) => {
    var collapseElement = document.getElementById('navbarCollapse')
    if(collapseElement.classList.contains('collapse')){
      collapseElement.classList.remove('collapse')
    }else{
      collapseElement.classList.add('collapse')
    }
  }

  return (
    <div className="navigation bg-white">
      <Web3Modal config={config}/>
      <nav className="navbar navbar-expand-lg container d-flex flex-row">

          <NavLink className="navbar-brand col col-lg-4" to="/">
            <img id="logo" src={logo}></img>
          </NavLink>
          <button class="navbar-toggler navbar navbar-light ml-auto" type="button" data-toggle="collapse" data-target=".navbar-collapse" onClick={setCollapse}>
            <img id="navbar-button w-25" class="navbar-toggler" src={favicon}></img>
          </button>
          <div className="container">
          
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item col">
                <NavLink className="nav-link" to="/">
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item col">
                <NavLink className="nav-link" to="/write">
                  Write
                </NavLink>
              </li>
              <li className="nav-item col">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item col">
                <NavLink className="nav-link" to="/search">
                  Search
                </NavLink>
              </li>
              <li className="nav-item col-xl-5 col-xs-12">
                <button className="btn btn-white ml-1 w-100" onClick={account ? profile : () => open()}>{account ? account.substring(0,12)+"..." : "CONNECT WALLET"}</button>
              </li>
            </ul>
          </div>
          </div>
      </nav>
    </div>
  );
}

export default Navigation;
