import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import '../cloud.css';

function Home() {
  const navigate = useNavigate();
  const [vines, setVines] = useState([]);

  useEffect(() => {
    // fetch vines from backend
    fetch("/api/vines")
      .then((res) => res.json())
      .then((data) => setVines(data))
  },[]);

  const handleClick = (event) => {
    // redirect to first vine
    const vineName = event.target.getAttribute('value')
    fetch(`/api/vine/${encodeURI(vineName)}/stories?limit=5`)
      .then((res) => res.json())
      .then((data) => { navigate(`/story/${data[0].storyId}`) })
  };

  const size = () => {
    return Math.floor(Math.random()*(20-5)+5);
  }

  return (
    <div className="home w-100">
      <div class="w-100">
        <div class="row align-items-center w-100">
          <ul class="cloud w-100 fade-in">
            {vines.map((vine) => (
              <li key={vine.vineName}>
                  <h1 className="font-weight-light" style={{"--size": size()}} value={vine.vineName} onClick={handleClick}>{vine.vineName}...</h1>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Home;
