import React from "react";
import { useState, useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {Button, Modal} from 'react-bootstrap';
import { useConnectModal, Web3Modal } from '@web3modal/react';
import 'react-quill/dist/quill.snow.css';

function Write({account}) {
  const navigate = useNavigate();
  let { draftSlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState('');
  const [vines, setVines] = useState([]);
  const [localVines, setLocalVines] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [vine, setVine] = useState('');
  const [storyId, setStoryId] = useState(null);
  const reference = useRef({value: '', vine: ''});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { isOpen, open, close } = useConnectModal();

  useEffect(() => { 
    const vineParam = searchParams.get("vine")
    if(draftSlug){
      reference.draftId = draftSlug
      getDraft()
      setShowSearch(true)
    }else if(vineParam){
      setVine(vineParam)
      setShowSearch(true)
    }

    return () => {
      if(!reference.published && reference.value.length > 1 && account){
        putDraft(reference)
      }
    }
  },[draftSlug, account]);

  useEffect(() => {
    reference.value = value
    reference.vine = vine
  },[value, vine]);

  const modules = {
    toolbar: [
      ['italic', 'link'],
    ],
  };

  const getVines = event => {
    const vineName = event.target.value
    setShowSearch(false)
    setVine(vineName)
    if(vineName){
        var queriedVines = localVines;
        var currentVines = queriedVines.filter(e => { return e.vineName.toLowerCase().includes(vineName.toLowerCase())})
        if(currentVines.length < 1){
            fetch(`/api/vines?vineName=${encodeURI(vineName)}`)
                .then(response => response.json())
                .then(data => {
                    queriedVines = queriedVines.concat(data)
                    currentVines = queriedVines.filter(e => { return e.vineName.toLowerCase().includes(vineName.toLowerCase())})
                    setLocalVines(queriedVines)
                    setVines(currentVines)
                });
        }else{
            setVines(currentVines)
        }
    }
  };

  const getDraft = () => {
    if(draftSlug && account){
      fetch(`/api/draft/${draftSlug}`)
        .then((res) => res.json())
        .then((data) => {
          if(account == data.author){
            setVine(data.vine)
            setValue(data.content)
          }else{
            navigate('/write')
          }
        });
    }
  };

  const putDraft = (ref) => {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        draftId: ref.draftId ? ref.draftId : null,
        vine: ref.vine,
        content: ref.value,
        author: account ? account : 'anonymous'
      })
    }
    fetch('/api/draft', options)
      .then(response => response.json())
      .then(data => {console.log(data)})
  };

  const deleteDraft = () => {
    const options = {method: 'DELETE'}
    fetch(`/api/draft/${reference.draftId}`, options)
    .then(response => console.log(response))
  };

  const handlePublish = event => {
    //event.preventDefault();
    reference.published = true;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        vine: vine,
        content: value,
        author: account != "" ? account : 'anonymous'
      })
    };
    fetch('/api/story', options)
      .then(response => response.json())
      .then(data => {
        deleteDraft()
        setStoryId(data.storyId);
        handleRedirect(data.storyId);
    });
  };

  const handleRedirect = (id) => {
    navigate(`/story/${id}`)
  };

  const wordCount = () => {
    return value.replace(/<[^>]+>/g, '').split(" ").length-1;
  }

  var border = {
    border: 'none',
  }

  const setInput = (vineName) => {
    setShowSearch(true)
    setVine(vineName + '...')
  }

  return (
    <div className="write h-100">
      <div className="container h-100">

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{account=="" ? "No Wallet" : "Published"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{account=="" ? "Would you like to connect your wallet" : "You've published a story"}</Modal.Body>
          <Modal.Footer>
            { account== "" ?  <Button variant="white" onClick={ handlePublish }>Publish Anyway</Button> : null}
            <Button variant="white" onClick={account == "" ? ()=>open() : handlePublish}>
            { account== "" ? "Connect Wallet" : "Publish Story" }
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="row align-items-center mt-5 mb-2">
          <div className="col-lg-12 d-flex">
              <input style={border} id="vineBox"  className="font-weight-light w-100" placeholder="Vine..." onChange={getVines} value={vine}/>
          </div>
          <div hidden={showSearch} className="col-lg-12">
              {vines.map((vine) => (
                  <div className="card" key={vine.vineName} onClick={() => setInput(vine.vineName)}>
                      <div className="card-body">
                          {vine.vineName}...
                      </div>
                  </div>
              ))}
              <div hidden={vine.length > 0 && vines.length < 1 ? false : true} className="card" key={vine.vineName} onClick={() => {setShowSearch(true); setVine(vine + '...')}}>
                  <div className="card-body">
                      <button className="btn btn-white mt-1">CREATE NEW VINE</button>
                  </div>
              </div>
          </div>
        </div>
        <div className="row h-75">
          <div className="col-lg-12">
            <ReactQuill className="h-75" theme="snow" modules={modules} value={value} onChange={setValue} />
          </div>
        </div>
        <div className="row align-items-center mt-1">
          <div className="col-lg-6">
            <h4 id="counter" className="mt-1 arial">WORDS: {wordCount()}</h4>
          </div>
          <div className="col-lg-6">
            <button className="btn btn-white w-100" disabled={wordCount() > 600 ? true : false} onClick={handleShow}>PUBLISH</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Write;
