import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router";

function Controller({account, getBalance, getAllTransactions}) {
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState(0);
  const [analytics, setAnalytics] = useState([]);

  useEffect(() => {
    if(account){

        getBalance()
        .then(res => {setBalance(res.toNumber())})

        fetch("/api/controller/analytics")
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                return setAnalytics(data)
            })

        getAllTransactions()
        .then(res => {
            console.log(res)
            return setTransactions(res)
        })
    }
  },[]);


  return (
    <div className="controller">
      <div className="container">

        <div className="row mt-2">
          <div className="col">
            <h3>Supply: {balance} FLWR</h3>
          </div>
          <div className="col">
            
          </div>
        </div>

        <h1>Table Counts</h1>
        <div className="row">
           {analytics.map((table) => (
              <div className="card mt-1 mb-1 col-4">
                <div className="card-body">
                  <h5 className="card-title">Table: {table.tableName}</h5>
                  <p className="card-text overflow-auto">{table.count}</p>
                </div>
              </div>
           ))}
        </div>

        <h1>Transactions</h1>
        <div className="row">
           {transactions.map((transaction) => (
              <div className="card mt-1 mb-1 col-4">
                <div className="card-body">
                  <h5 className="card-title">Event: {transaction.event}</h5>
                  <small>{(transaction.event == "Transfer") ? `To: ${transaction.args.to}` : `Contract Address: ${transaction.address}`}</small>
                  <p className="card-text overflow-auto">{(transaction.event == "Transfer") ? `Amount: ${transaction.args.value.toNumber()} FLWR` : `Transaction Hash: ${transaction.transactionHash}`}</p>
                </div>
              </div>
           ))}
        </div>
      </div>
    </div>
  );
}

export default Controller;
